

.refund-dialouge{
    padding-left: 16px;
    background: #e1e1f8;
    margin-bottom: 5px;
    padding-top: 10px;
}
.refund-dialouge .month-label {
    margin: 0;
    font-size: 15px;
}

.refund-dialouge .month-no {
 font-weight: bold;
}
.refund-dialouge .msg {
    font-size: 14px;
    margin: 0;
    padding-bottom: 10px;
}

.refund-adjst {
    background: rgb(243, 225, 225);
    margin-bottom: 5px;
    padding-top: 10px;
    padding-left: 16px;
    padding-bottom: 10px;
}

.refund-adjst .control-t{
    display: flex;
    align-items: center;
    
}

.refund-adjst .control-t .label{
   margin: 0;
   width: 43%;
   font-size: 14px;
}

.refund-adjst .control-t.error .MuiFormHelperText-root{
    color: red !important;
}