body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.transaction-table .MuiTableCell-sizeSmall {
  padding: 6px 3px 6px 3px;
}
.transaction-table {
  margin-left: 5%;
  width: 90%;
}

.transaction-table-list .MuiTableCell-sizeSmall {
  padding: 6px 10px 6px 10px;
}
.MuiTableCell-sizeSmall {
  padding: 6px 24px 6px 6px !important;
}
.split-up-contain {
  margin: 20px 80px;
  width: 100%;
}
.amount-splitup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 20px 80px; */
  padding: 10px 30px;
  background: rgb(243, 225, 225);
}

.amount-splitup .MuiTableCell-root {
  border-bottom: 1px solid rgb(93 137 236);
}

.amount-splitup .amt-check-box {
  padding: 0;
}

.amount-splitup .fees-type .fees-title {
  margin-left: 5px;
}

.amount-splitup .total .fees-title {
  margin-left: 29px;
}

.page-notfound {
  width: 100%;
  background: #f5cfb4;
  padding-top: 10px;
}
.page-notfound p {
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
