.login{
    background: gray;
    height:100vh;
}

.contain22er{
    margin-top: 20px;
}
.bodyColor{
    background: gray !important;
}

.login .login-card{
    box-shadow: 0 5px 15px rgba(0,0,0,.5) !important;
    border-radius: 0% !important;
    /* width: 350px;
    height: 450px; */
}

.logo{
    text-align: center;
}

.logo-custom{
    max-width: 80%;
    max-height: 60%;
}

.main-form{
    background:#EAE8DB;
    padding: 20px;
    margin-top: 20px;
}
.user-mgmt-form{
    padding: 20px;
    margin-top: 20px;
}
.label-head{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: black;
    font-weight: 400;
}

.a-input{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding: 6px 12px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    line-height: 1.42857;
}

.login form p{
    margin-bottom: 5px;
}

.a-form-ctrl{
    margin-bottom: 5px;
}

.forgot-pawd{
    color: #337ab7;
    cursor: pointer;
}
.forgot-pawd a{
    color: #337ab7;
    cursor: pointer;
}
.forgot-pawd:hover{
    text-decoration: underline;
}

.a-btn{
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: #2e6da4 !important;
    border-color: #2e6da4 !important;
    background-image: none;
    border: 1px solid #2e6da4;
    border-radius: 4px;
}

.a-btn1{
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 220%;
    color: #fff;
    background-color: #fb6647;
    border-color: #2e6da4;
    background-image: none;
    border: 1px solid #2e6da4;
    border-radius: 4px;
}
.a-btn:hover{
    color: #fff;
    background-color: #a8d6ff;
}

.pwd-validations .valid{
    color:#19BF00
}
.pwd-validations .invalid{
    color:#DF3312
}
.pwd-validations .pwd-validation-txt{
    margin-left: 5px;
}

.a-errorMessage{
    padding: 5px;
    font-size: 14px;
    width: 100%;
    background: #F5F5F5;
    border: 2px solid #D64958;
    color: #D64958;
    margin-bottom: 10px;
    font-weight: 400;
}

.login button.a-btn[disabled] {
    color: #ffffff85;
    pointer-events: none;
    background: #fb664778;
}
.a-pwd-visibility {
    display: flex;
    justify-content: flex-end;
    margin: -27px 10px 27px;
}